import axios from "axios";
import { useEffect } from "react";


const axiosApi = axios.create({
    baseURL: "https://s8d9jqgusf.execute-api.us-east-1.amazonaws.com/dev",
  });


  // add CUSTOMER
export const getTask = data => post("https://s8d9jqgusf.execute-api.us-east-1.amazonaws.com/dev", data);


 async function post(url, data, config = {}) {
    const headers = {
        "x-api-key": "dLhSheH5JA971CHlMNKjc8snyTIl6Gy946cTtFIM",
        "Content-Type": "application/json"
      };
      try {
    return axiosApi
      .post(url, { ...data },{ headers })
      .then((response) => response.data);
    } catch (error) {
        const errorMessage = 'Something went wrong, try again later';

        // Create a new error object with the custom message
        const customError = new Error(errorMessage);
    
        // Throw the custom error
        throw customError;
    }
  }
