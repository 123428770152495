/* TASKS */
export const EXECUTE_QUERY = "EXECUTE_QUERY"
export const EXECUTE_QUERY_SUCCESS = "EXECUTE_QUERY_SUCCESS"
export const EXECUTE_QUERY_FAIL = "EXECUTE_QUERY_FAIL"

export const EXECUTE_QUERY_BULK = "EXECUTE_QUERY_BULK"
export const EXECUTE_QUERY_BULK_SUCCESS = "EXECUTE_QUERY_BULK_SUCCESS"
export const EXECUTE_QUERY_BULK_FAIL = "EXECUTE_QUERY_BULK_FAIL"


export const SET_LOADING_INDICATOR_SMART_INTAKE = "SET_LOADING_INDICATOR_SMART_INTAKE"
