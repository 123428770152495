import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios";

// Crypto Redux States
import { getTasksSuccess, getTasksFail, executeQuerySuccess, executeQueryFail, setLoadingIndicatorSmartIntake, executeQueryBulkSuccess, executeQueryBulkFail } from "./actions"

//Include Both Helper File with needed methods
import { getTasks } from "helpers/fakebackend_helper"
import { EXECUTE_QUERY, EXECUTE_QUERY_BULK } from "./actionTypes"
import { getTask } from "./getTask";
import { getTaskBulk } from "./getTaskBulk";

const local_store_name = 'chatMessagesICU'

function* ExecuteSmartIntakeQuery({payload: {data}}) {

  yield put(setLoadingIndicatorSmartIntake(true));

  // console.log(data)
  try {
    // const response = yield call(getTasks)
    // const url = "https://b9mt4rqsak.execute-api.us-east-1.amazonaws.com/production";

    //   const headers = {
    //     "x-api-key": "CuDJRIJUrmaxPYZYx2Gbr37vFuiZZMq1AqMdSxpb",
    //     "Content-Type": "application/json"
    //   };
    //   const response = yield call(axios.post(url, data, { headers }));
    //   console.log(response)
    // yield put(executeQuerySuccess(''))
    // axios.get(url, { headers }).then(response=>{
    //   console.log(response.data)
    //   executeQuerySuccesss('')
    // })

    const response = yield call(getTask, data);
    // console.log(response);
     // Retrieve existing messages from localStorage

    //  console.log(response)
     
     const existingMessages = JSON.parse(localStorage.getItem(local_store_name)) || [];

     // Append the new message to the existing ones
     const updatedMessages = [...existingMessages, { user: "bot", text: response.results.initial_rml_coding, confidence_score : response.results.search_confidence_score, reranker_confidence_score : response.results.reranker_score }];

       // Save the updated messages back to localStorage
    localStorage.setItem(local_store_name, JSON.stringify(updatedMessages));

 
    yield put(executeQuerySuccess(response))

  } catch (error) {
    yield put(executeQueryFail(error))
  }
}


function* ExecuteQueryBulk({payload: {data}}) {

  // yield put(setLoadingIndicatorSmartIntake(true));

  // console.log(data)
  try {
   
    const response = yield call(getTaskBulk, data);
  
    yield put(executeQueryBulkSuccess(response))

  } catch (error) {
    yield put(executeQueryBulkFail(error))
  }
}


function* tasksSaga() {
  yield takeEvery(EXECUTE_QUERY, ExecuteSmartIntakeQuery)
  yield takeEvery(EXECUTE_QUERY_BULK, ExecuteQueryBulk)

}

export default tasksSaga
