import axios from "axios";

const axiosApi = axios.create({
  baseURL: "https://kqfehqez1f.execute-api.us-east-1.amazonaws.com/dev/",
});

// add CUSTOMER
export const getTaskBulk = (data) =>
  post("https://kqfehqez1f.execute-api.us-east-1.amazonaws.com/dev/", data);

async function post(url, data, config = {}) {
  const formData = new FormData();

  // Append `file` and `emails` to formData
  if (data.file) {
    formData.append("file", data.file); // assuming `data.file` is a File object
  }

  if (data.emails) {
    const emailArray = data.emails.split(','); // Split emails by comma
    emailArray.forEach(email => {
      formData.append("recipient_emails", email.trim()); // Trim and append each email individually
    });
  }

  const headers = {
    // "x-api-key": "dLhSheH5JA971CHlMNKjc8snyTIl6Gy946cTtFIM",
    "Content-Type": "multipart/form-data", // updated content type
  };

  try {
    const response = await axiosApi.post(url, formData, { headers });
    return response.data;
  } catch (error) {
    console.log(error)
    const errorMessage = "Something went wrong, try again later";

    // Throw a custom error with the custom message
    throw new Error(errorMessage);
  }
}
